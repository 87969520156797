import Image from 'next/image';

import {
  publicationForbes,
  publicationBloomberg,
  publicationCnbc,
  publicationNewYorkTimes,
  publicationWallStreetJournal,
} from '../../assets/icons';

const Publications = () => {
  const brands = [
    {
      title: 'Forbes',
      image: publicationForbes,
    },
    {
      title: 'Bloomberg',
      image: publicationBloomberg,
    },
    {
      title: 'CNBC',
      image: publicationCnbc,
    },
    {
      title: 'NewYorkTimes',
      image: publicationNewYorkTimes,
    },
    {
      title: 'WallStreetJournal',
      image: publicationWallStreetJournal,
    },
  ];

  return (
    <div className="bg-arctic-200 pt-24 lg:mt-0">
      <section className="flex flex-col items-center gap-6 px-12">
        <div className="flex flex-col items-center gap-6 lg:flex-row lg:gap-24">
          {brands.map((brand) => (
            <Image
              key={brand.title}
              unoptimized
              alt={brand.title}
              src={brand.image}
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          ))}
        </div>
        <div>
          <p className="text-center text-xs text-black-600">
            A few publications mentioning the revolutionary GLP-1 medications
          </p>
        </div>
      </section>
    </div>
  );
};

export default Publications;
