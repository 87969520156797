export const buttonVariantClassnames = {
  primary:
    'bg-arctic-900 border border-teal-900 text-teal-900 hover:bg-sky-900 hover:border-2 hover:-translate-y-0.5',
  secondary: 'bg-orange-900 text-white hover:bg-yellow-900',
  text: 'text-cyan-900 hover:bg-gray-100',
  outlined: 'border border-teal-900 rounded text-teal-900 hover:bg-gray-100',
};

export const baseButtonClassname =
  'font-medium text-center px-4 rounded-md py-2 text-sm ease-in-out transition-all tracking-wide';
