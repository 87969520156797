import { GoStarFill, GoStar } from 'react-icons/go';
import Image from 'next/image';

interface TestimonialsItemProps {
  testimony: string;
  author: string;
  rating: number;
  poundsLost?: number;
  image?: string;
}

const TestimonialsItem = ({
  testimony,
  author,
  rating,
  poundsLost,
  image,
}: TestimonialsItemProps) => {
  const ratingStars = Array.from(Array(5).keys());

  return (
    <div className="flex flex-col gap-8 rounded-lg bg-white p-6 shadow-md lg:flex-row">
      <div className="flex justify-center">
        {image && (
          <div className=" flex h-28 w-28 flex-col items-center justify-center lg:h-36 lg:w-36">
            <Image alt="Pounds lost" height={144} src={image} width={144} />
          </div>
        )}
        {poundsLost && (
          <div className="flex h-28 w-28 flex-col justify-center rounded-full text-center shadow-md shadow-orange-900">
            <p className="text-xs font-semibold uppercase text-teal-900">Lost</p>
            <p className="text-4xl font-semibold text-teal-900">{poundsLost}</p>
            <p className="text-xs font-semibold uppercase text-teal-900">pounds</p>
          </div>
        )}
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex justify-center gap-1 lg:justify-start">
          {ratingStars.map((_, index) => {
            const starFill = index + 1 <= rating;
            const starColor = 'fill-orange-900 h-6 w-6';

            return starFill ? (
              <GoStarFill key={`star-${index}`} className={starColor} />
            ) : (
              <GoStar key={`star-${index}`} className={starColor} />
            );
          })}
        </div>
        <p className="text-sm text-black-800 lg:text-xs">{testimony}</p>
        <p className="text-sm font-bold text-teal-900 lg:text-xs lg:font-semibold">{author}</p>
      </div>
    </div>
  );
};

export default TestimonialsItem;
