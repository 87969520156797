'use client';

import { useSearchParams } from 'next/navigation';

import StripePromoCodeValidator from './StripePromoCodeValidator';

export const ParamValidator = () => {
  const searchParams = useSearchParams();

  const referrerPromoCode = searchParams.get('promo_code');

  if (!referrerPromoCode) {
    return null;
  }

  return <StripePromoCodeValidator referrerPromoCode={referrerPromoCode} />;
};
