interface AgileAboutProps {
  text: string;
}
const AgileAbout = ({ text }: AgileAboutProps) => {
  return (
    <div className="bg-arctic-200 py-24 lg:mt-0 lg:pb-36 lg:pt-12">
      <section className="flex max-w-4xl flex-col items-center gap-12 lg:mx-auto">
        <div className="lg:h-[556px] lg:w-full lg:max-w-4xl">
          <video
            autoPlay
            loop
            muted
            playsInline
            className="h-80 w-auto max-w-none lg:h-full lg:w-full lg:object-cover"
            src={'/videos/AgileAbout.mp4'}
          />
        </div>
        <div className="mx-4 flex flex-col gap-6">
          <h2 className="text-4xl font-medium lg:text-3xl lg:font-normal">
            About Agile Telehealth:
          </h2>
          <p className="lg:text-sm">{text}</p>
        </div>
      </section>
    </div>
  );
};

export default AgileAbout;
