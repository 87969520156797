import Image from 'next/image';
import Link from 'next/link';

import Button from 'components/Button';
import { scrollToSection } from 'utils';

export interface whatsIncludedElement {
  icon: { url: string };
  description: string;
}
interface WhatsIncludedProps {
  whatsIncludedText: string;
  buttonLink?: string;
  buttonRef: React.RefObject<HTMLDivElement>;
  buttonText: string;
  whatsIncludedSectionCollection: whatsIncludedElement[];
  title: string;
}
const buttonStyles =
  'inline-block w-full rounded-md border border-teal-900 bg-arctic-900 px-4 py-2 text-center text-sm font-bold uppercase tracking-wide text-teal-900 transition-all ease-in-out hover:-translate-y-0.5 hover:border-2 hover:bg-sky-900 lg:font-semibold';

const WhatsIncluded = ({
  whatsIncludedText,
  buttonRef,
  buttonLink,
  buttonText,
  title,
  whatsIncludedSectionCollection,
}: WhatsIncludedProps) => {
  return (
    <div className="bg-arctic-200 pb-16 pt-24 lg:mt-0 lg:pb-8 lg:pt-12">
      <section className="flex max-w-4xl flex-col items-center lg:mx-auto lg:flex-row-reverse">
        <div className="lg:ml-[-20px] lg:h-[500px] lg:w-3/4">
          <video
            autoPlay
            loop
            muted
            playsInline
            className="h-72 w-auto max-w-none lg:h-full lg:w-full lg:object-cover"
            src={'/videos/WhatsIncluded.mp4'}
          />
        </div>
        <div className="bg-gradient-to-b from-white/60 to-orange-400/60 lg:z-[2]">
          <div className="mx-4 my-8 flex flex-col gap-6 rounded-lg bg-white p-8">
            <h3 className="text-2xl font-medium lg:text-xl lg:font-normal">{title}</h3>
            <div className="flex flex-col gap-6">
              {whatsIncludedSectionCollection.map((element, index) => {
                return (
                  <div
                    key={`element-${index}`}
                    className="flex items-center gap-3 border-b border-teal-400 pb-6"
                  >
                    <Image
                      alt={'Whats included icon'}
                      className="h-6 w-6 fill-teal-900"
                      height={42}
                      src={element.icon.url}
                      width={42}
                    />
                    <p className="w-4/5 text-black-800 lg:text-sm">{element.description}</p>
                  </div>
                );
              })}
            </div>
            <p className="text-center text-2xl font-medium text-orange-900 lg:text-xl">
              {whatsIncludedText}
            </p>
            <div className="flex w-full flex-col ">
              {buttonLink ? (
                <Link className={buttonStyles} href={buttonLink}>
                  {buttonText}
                </Link>
              ) : (
                <Button
                  className={buttonStyles}
                  variant="primary"
                  onClick={() => scrollToSection(buttonRef)}
                >
                  {buttonText}
                </Button>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WhatsIncluded;
