'use client';
import { type RefObject, useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';

import BluePen from 'assets/images/blue-pen.png';
import BluePen2 from 'assets/images/blue-pen-2.png';
import { scrollToSection } from 'utils';
import Button from 'components/Button';

interface WeightLossEstimatorProps {
  title: string;
  buttonLink?: string;
  buttonRef: RefObject<HTMLDivElement>;
  buttonText: string;
}

const buttonStyles =
  'inline-block w-full rounded-md border border-teal-900 bg-arctic-900 px-4 py-2 text-center  text-sm font-bold uppercase tracking-wide text-teal-900 transition-all ease-in-out hover:-translate-y-0.5 hover:border-2 hover:bg-sky-900 lg:w-1/4 lg:text-center lg:font-semibold';

const WeightLossEstimator = ({
  buttonRef,
  buttonLink,
  buttonText,
  title,
}: WeightLossEstimatorProps) => {
  const [currentWeight, setCurrentWeight] = useState<number | string>(200);
  const [potentialWeightLoss, setPotentialWeightLoss] = useState(0);

  useEffect(() => {
    const weightLoss = Math.round(Number(currentWeight) * 0.2 * 100) / 100;

    setPotentialWeightLoss(weightLoss);
  }, [currentWeight]);

  const roundWeight = (weight: number) => {
    let newValue = 0;

    if (weight > 500) newValue = 500;
    else if (weight < 0) newValue = 0;
    else newValue = Math.round(weight);

    return Number(newValue).toString();
  };

  return (
    <div className=" bg-arctic-200 pb-4 pt-24 lg:mt-0">
      <div className="relative">
        <Image
          alt="first blue pen"
          className="absolute right-[150px] top-[-82px] z-0 hidden h-[286px] w-[100px] rotate-[216deg] lg:block xl:right-[275px] xl:top-[-71px]"
          src={BluePen}
        />
        <Image
          alt="second blue pen"
          className="z-1 right-[142px] top-[-69px] hidden h-[286px] w-[130px] rotate-[216deg] lg:absolute lg:block xl:right-[267px] xl:top-[-58px]"
          src={BluePen2}
        />
      </div>
      <section className="mx-4 flex max-w-4xl flex-col items-center gap-10 lg:mx-auto lg:px-10">
        <div>
          <h2 className="text-center text-4xl font-medium lg:text-3xl lg:font-normal">{title}</h2>
        </div>
        <div className="z-[5] flex flex-col items-center gap-6 rounded-lg bg-teal-900 p-8 lg:flex-row">
          <div className="flex w-full  flex-col items-center rounded-lg bg-white py-6 lg:h-52 lg:w-fit">
            <p className="px-8 text-center text-lg text-cyan-900 lg:text-base">
              Select Your Currrent Weight:
            </p>
            <div className="w-full px-4 py-3">
              <input
                className="h-3 w-full appearance-none rounded-full bg-cyan-200
                [&::-webkit-slider-thumb]:h-[0px] [&::-webkit-slider-thumb]:w-[0px] 
                [&::-webkit-slider-thumb]:cursor-pointer [&::-webkit-slider-thumb]:appearance-none 
                [&::-webkit-slider-thumb]:border-x-[12px] [&::-webkit-slider-thumb]:border-b-0 [&::-webkit-slider-thumb]:border-t-[20px]
                [&::-webkit-slider-thumb]:border-solid [&::-webkit-slider-thumb]:border-orange-900 [&::-webkit-slider-thumb]:border-x-transparent"
                max="500"
                min="0"
                step="1"
                type="range"
                value={currentWeight}
                onChange={(e) => setCurrentWeight(Number(e.target.value))}
              />
            </div>
            <div className="flex items-end px-8 text-center font-semibold text-teal-900">
              <input
                className="w-full appearance-none rounded border border-black-400 text-right text-5xl font-medium outline-none"
                max="500"
                min="0"
                step="1"
                type="number"
                value={currentWeight}
                onChange={(e) => setCurrentWeight(roundWeight(Number(e.target.value)))}
              />
              <span className="pb-3">LBS</span>
            </div>
          </div>
          <div className="flex w-full flex-col gap-4 rounded-lg bg-white py-6 lg:h-52 lg:w-fit">
            <p className="px-8 text-center text-lg text-cyan-900 lg:text-base">
              Potential Weight Loss
            </p>
            <p className="px-8 text-center font-semibold text-orange-900">
              <span className="text-5xl font-medium">{potentialWeightLoss}</span> LBS
            </p>
          </div>
          <div className="lg:w-2/5">
            <p className="text-sm text-white lg:text-xs">
              **In a 68- and 72-week clinical trial studying Wegovy (2.4 mg) and Zepbound (15 mg) in
              patients without diabetes and with BMI ≥30, or BMI ≥27 with a weight-related
              condition, the average weight loss was 15% and 20%, when paired with diet and exercise
              changes (compared to 2.4% and 3.1%, respectively, with diet and exercise alone).
            </p>
          </div>
        </div>
        <div className="flex w-full flex-col lg:items-center">
          {buttonLink ? (
            <Link className={buttonStyles} href={buttonLink}>
              {buttonText}
            </Link>
          ) : (
            <Button
              className={buttonStyles}
              variant="primary"
              onClick={() => scrollToSection(buttonRef)}
            >
              {buttonText}
            </Button>
          )}
        </div>
      </section>
    </div>
  );
};

export default WeightLossEstimator;
