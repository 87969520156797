import Image from 'next/image';
import Link from 'next/link';
import { type RefObject } from 'react';

import hero from 'assets/images/hero-banner.jpeg';
import Button from 'components/Button';
import { scrollToSection } from 'utils';

interface HeroBannerProps {
  heroTitle: string;
  heroSubtitle: string;
  heroCtaLink?: string;
  heroCtaText: string;
  heroCtaRef: RefObject<HTMLDivElement>;
  heroBadge?: { url: string };
}

const buttonStyles =
  'bg-arctic-900 border border-teal-900 text-teal-900 hover:bg-sky-900 hover:border-2 hover:-translate-y-0.5 tracking-wide font-bold text-center px-4 rounded-md py-2 text-sm ease-in-out transition-all uppercase lg:w-1/4 lg:font-semibold';

const HeroBanner = ({
  heroTitle,
  heroSubtitle,
  heroCtaLink,
  heroCtaText,
  heroCtaRef,
  heroBadge,
}: HeroBannerProps) => (
  <div className="mx-auto flex h-[36rem] max-w-4xl flex-col  lg:h-auto">
    <div className="ml-6 mr-6 mt-8  flex flex-col items-center gap-6 lg:mt-12 lg:h-auto">
      <h1 className="text-center text-4xl font-medium text-teal-900 lg:text-3xl lg:font-normal">
        {heroTitle}
      </h1>
      <h3 className="text-center text-2xl font-light text-black-900 lg:text-xl">{heroSubtitle}</h3>

      {heroCtaLink ? (
        <Link className={buttonStyles} href={heroCtaLink}>
          {heroCtaText}
        </Link>
      ) : (
        <Button
          className={buttonStyles}
          variant="primary"
          onClick={() => scrollToSection(heroCtaRef)}
        >
          {heroCtaText}
        </Button>
      )}
    </div>
    <div className="relative mt-6">
      {heroBadge?.url && (
        <div className="absolute flex w-full justify-center lg:left-16 lg:top-8 lg:w-auto">
          <div className="z-[4] h-32 w-32">
            <Image alt="Month price" height={150} src={heroBadge.url} width={150} />
          </div>
        </div>
      )}
      <div className="mt-20 lg:mt-0">
        <div className="md:h-68 relative h-56 overflow-hidden sm:h-72 lg:h-[22rem]">
          <Image
            priority
            alt="physician and patient"
            className="h-full scale-150 object-cover object-[-22px_-11px] sm:scale-100 sm:object-center lg:rounded-t-full lg:object-[0px_-150px]"
            placeholder="blur"
            quality={100}
            src={hero}
          />
        </div>
      </div>
    </div>
  </div>
);

export default HeroBanner;
