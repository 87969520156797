'use client';

import { useQuery } from '@tanstack/react-query';

import { getValidPromotionCode } from 'lib/StripeClient';

import PromoCodeEffect from './PromoCodeEffect';

interface PromoCodeValidatorProps {
  referrerPromoCode: string;
}

export async function StripePromoCodeValidator({ referrerPromoCode }: PromoCodeValidatorProps) {
  const { data: validatedPromoCode, isPending } = useQuery({
    queryKey: ['stripePromoCodeValidator', referrerPromoCode],
    queryFn: async () => {
      return await getValidPromotionCode({ promoCode: referrerPromoCode });
    },
    select: ({ data }) => data,
    enabled: !!referrerPromoCode,
  });

  if (isPending || validatedPromoCode === undefined) {
    return null;
  }

  return <PromoCodeEffect validatedPromoCode={validatedPromoCode} />;
}

export default StripePromoCodeValidator;
