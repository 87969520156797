import { type ButtonHTMLAttributes, type ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import Spinner from 'components/Spinner';
import { baseButtonClassname, buttonVariantClassnames } from 'utils/styles';

interface ButtonProps {
  children: ReactNode;
  variant: 'primary' | 'secondary' | 'outlined' | 'text';
  isLoading?: boolean;
}

const disabledBtnClassname = 'border-0 cursor-not-allowed bg-gray-400 hover:bg-gray-400 text-white';

const Button = ({
  isLoading,
  variant,
  children,
  className,
  ...props
}: ButtonHTMLAttributes<any> & ButtonProps) => (
  <button
    className={twMerge(
      baseButtonClassname,
      buttonVariantClassnames[variant],
      props.disabled && disabledBtnClassname,
      className,
    )}
    {...props}
  >
    <div className="flex items-center justify-center gap-2">
      {children}
      {isLoading && (
        <Spinner className={twMerge('-mr-1 h-4', variant !== 'primary' && 'text-gray-300')} />
      )}
    </div>
  </button>
);

export default Button;
