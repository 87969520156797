'use client';

import { useState, useEffect, type Dispatch, type SetStateAction } from 'react';

type SetValue<T> = Dispatch<SetStateAction<T>>;
type WebStorageType = 'localStorage' | 'sessionStorage';

const isClient = typeof window !== 'undefined';

const readFromWebStorage = <T>(key: string, fallback: T, storageType: WebStorageType): T => {
  try {
    if (!isClient) {
      return fallback;
    }

    const storedValue = window[storageType].getItem(key);

    return storedValue ? JSON.parse(storedValue) : fallback;
  } catch (error) {
    console.warn(`Error reading ${storageType} key “${key}”:`, error);

    return fallback;
  }
};

export const useWebStorage = <T>(
  key: string,
  initialValue: T,
  storageType: WebStorageType,
): [T, SetValue<T>, () => void] => {
  const [storedValue, setStoredValue] = useState<T>(() =>
    readFromWebStorage(key, initialValue, storageType),
  );

  useEffect(() => {
    try {
      if (!isClient) {
        return;
      }

      if (storedValue !== undefined) {
        window[storageType].setItem(key, JSON.stringify(storedValue));
      }
    } catch (error) {
      console.warn(`Error setting ${storageType} key “${key}”:`, error);
    }
  }, [key, storageType, storedValue]);

  const clearStoredValue = () => {
    try {
      if (!isClient) {
        return;
      }

      window[storageType].removeItem(key);
    } catch (error) {
      console.warn(`Error clearing ${storageType} key “${key}”:`, error);
    }
  };

  return [storedValue, setStoredValue, clearStoredValue];
};
