'use client';

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  CarouselDots,
} from 'components/Carousel/Carousel';
import TestimonialsItem from 'components/TestimonialsItem';

export interface Testimonial {
  author: string;
  testimonial: string;
  rating: number;
  image: { url: string };
}

interface TestimonialsProps {
  items: Testimonial[];
  title: string;
}

const Testimonials = ({ items, title }: TestimonialsProps) => {
  return (
    <div className="bg-arctic-200 pb-32 pt-24 lg:mt-0 lg:pb-24">
      <section className="flex max-w-4xl flex-col items-center gap-10 lg:mx-auto lg:px-12">
        <div className="mx-4">
          <h2 className="text-center text-4xl font-medium lg:text-3xl lg:font-normal">{title}</h2>
        </div>
        <Carousel className="w-3/5 lg:w-4/5" opts={{ loop: true }}>
          <CarouselContent>
            {items.map((testimonial, index) => (
              <CarouselItem key={`testimonial-${index}`}>
                <TestimonialsItem
                  author={testimonial.author}
                  image={testimonial.image.url}
                  rating={testimonial.rating}
                  testimony={testimonial.testimonial}
                />
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious />
          <CarouselNext />
          <CarouselDots />
        </Carousel>
      </section>
    </div>
  );
};

export default Testimonials;
