'use client';
import { useRef } from 'react';

import AgileAbout from 'components/AgileAbout';
import { FAQ } from 'components/FAQ';
import HeroBanner from 'components/HeroBanner';
import HowOurProgramWorks from 'components/HowOurProgramWorks';
import MeetOurExperts from 'components/MeetOurExperts';
import ProgramBenefits from 'components/ProgramBenefits';
import Publications from 'components/Publications';
import Subscription from 'components/Subscription';
import Testimonials from 'components/Testimonials';
import WeightLossEstimator from 'components/WeightLossEstimator';
import WhatsIncluded from 'components/WhatsIncluded';
import { type benefitProps } from 'components/ProgramBenefits/ProgramBenefits';
import { type Testimonial } from 'components/Testimonials/Testimonials';
import { type stepProps } from 'components/HowOurProgramWorks/HowOurProgramWorks';
import { type whatsIncludedElement } from 'components/WhatsIncluded/WhatsIncluded';
import { type Expert } from 'components/MeetOurExperts/MeetOurExperts';
import { type WLFAQ } from 'components/FAQ/FAQ';
import { type subscriptionCardProps } from 'components/Subscription/Subscription';

interface HomePageProps {
  homepageData: {
    heroTitle: string;
    heroSubtitle: string;
    heroCtaLink: string;
    heroCtaText: string;
    heroBadge: { url: string };
    propsCollection: { items: benefitProps[] };
    testimonialSectionTitle: string;
    testimonialsCollection: { items: Testimonial[] };
    howOurProgramWorksSectionTitle: string;
    howOurProgramWorksSectionDescription: string;
    howOurProgramWorksSectionCollection: { items: stepProps[] };
    howOurProgramWorksCtaLink: string;
    howOurProgramWorksCtaText: string;
    weightLossEstimatorSectionTitle: string;
    weightLossEstimatorCtaLink: string;
    weightLossEstimatorCtaText: string;
    whatsIncludedSectionTitle: string;
    whatsIncludedText: string;
    whatsIncludedSectionCollection: { items: whatsIncludedElement[] };
    whatsIncludedCtaLink: string;
    whatsIncludedCtaText: string;
    meetOurExpertsSectionTitle: string;
    meetOurExpertsSectionDescription: string;
    meetOurExpertsSectionCollection: {
      items: Expert[];
    };
    meetOurExpertsCtaLink: string;
    meetOurExpertsCtaText: string;
    faqsCollection: { items: WLFAQ[] };
    aboutAgileSection: string;
    subscriptionCardCollection: { items: subscriptionCardProps[] };
  };
}
const HomePage = ({ homepageData }: HomePageProps) => {
  const {
    heroTitle,
    heroSubtitle,
    heroCtaLink,
    heroCtaText,
    heroBadge,
    propsCollection,
    testimonialSectionTitle,
    testimonialsCollection,
    howOurProgramWorksSectionTitle,
    howOurProgramWorksSectionDescription,
    howOurProgramWorksSectionCollection,
    howOurProgramWorksCtaLink,
    howOurProgramWorksCtaText,
    weightLossEstimatorSectionTitle,
    weightLossEstimatorCtaLink,
    weightLossEstimatorCtaText,
    whatsIncludedSectionTitle,
    whatsIncludedText,
    whatsIncludedSectionCollection,
    whatsIncludedCtaLink,
    whatsIncludedCtaText,
    meetOurExpertsSectionTitle,
    meetOurExpertsSectionDescription,
    meetOurExpertsSectionCollection,
    meetOurExpertsCtaLink,
    meetOurExpertsCtaText,
    faqsCollection,
    aboutAgileSection,
    subscriptionCardCollection,
  } = homepageData;

  const subscriptionSection = useRef<HTMLDivElement>(null);

  return (
    <main className="overflow-hidden">
      <HeroBanner
        heroBadge={heroBadge}
        heroCtaLink={heroCtaLink}
        heroCtaRef={subscriptionSection}
        heroCtaText={heroCtaText}
        heroSubtitle={heroSubtitle}
        heroTitle={heroTitle}
      />
      <ProgramBenefits items={propsCollection.items} />
      <Subscription
        subscriptionCards={subscriptionCardCollection.items}
        subscriptionRef={subscriptionSection}
        title={'Agile’s Weight Loss Programs'}
      />
      <WeightLossEstimator
        buttonLink={weightLossEstimatorCtaLink}
        buttonRef={subscriptionSection}
        buttonText={weightLossEstimatorCtaText}
        title={weightLossEstimatorSectionTitle}
      />
      <Publications />
      <Testimonials items={testimonialsCollection.items} title={testimonialSectionTitle} />
      <HowOurProgramWorks
        buttonLink={howOurProgramWorksCtaLink}
        buttonRef={subscriptionSection}
        buttonText={howOurProgramWorksCtaText}
        description={howOurProgramWorksSectionDescription}
        items={howOurProgramWorksSectionCollection.items}
        title={howOurProgramWorksSectionTitle}
      />
      <WhatsIncluded
        buttonLink={whatsIncludedCtaLink}
        buttonRef={subscriptionSection}
        buttonText={whatsIncludedCtaText}
        title={whatsIncludedSectionTitle}
        whatsIncludedSectionCollection={whatsIncludedSectionCollection.items}
        whatsIncludedText={whatsIncludedText}
      />
      <MeetOurExperts
        buttonLink={meetOurExpertsCtaLink}
        buttonRef={subscriptionSection}
        buttonText={meetOurExpertsCtaText}
        description={meetOurExpertsSectionDescription}
        items={meetOurExpertsSectionCollection.items}
        title={meetOurExpertsSectionTitle}
      />
      <FAQ items={faqsCollection.items} secondVersion={true} title={'Weight Loss FAQs'} />
      <AgileAbout text={aboutAgileSection} />
    </main>
  );
};

export default HomePage;
