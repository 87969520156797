'use client';
import { Transition } from '@headlessui/react';
import { useEffect, useState, useRef } from 'react';

import { animateOnScroll } from 'utils';
import ProgramBenefitItem from 'components/ProgramBenefitItem';

export interface benefitProps {
  icon: { url: string };
  title: string;
  description: string;
}

interface ProgramBenefitProps {
  items: benefitProps[];
}

const ProgramBenefits = ({ items }: ProgramBenefitProps) => {
  const delayOptions = ['delay-300', 'delay-500', 'delay-700'];

  const [show, setShow] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => animateOnScroll(setShow, sectionRef), []);

  return (
    <div ref={sectionRef} className="mt-16 bg-arctic-200 sm:mt-8 lg:mx-auto lg:mt-0">
      <section className=" flex flex-col gap-6 lg:flex-row lg:justify-center lg:gap-4">
        {items.map((benefit, index) => {
          const benefitDelay = delayOptions[index];

          return (
            <Transition
              key={`${index}-benefit`}
              appear={true}
              className="z-[1]"
              enter={`transition-all duration-500 ${benefitDelay}`}
              enterFrom="translate-y-10 opacity-0"
              enterTo="translate-y-0 opacity-100"
              show={show}
            >
              <ProgramBenefitItem
                description={benefit.description}
                imageAlt={'Benefit icon'}
                imageSrc={benefit.icon.url}
                title={benefit.title}
              />
            </Transition>
          );
        })}
      </section>
    </div>
  );
};

export default ProgramBenefits;
