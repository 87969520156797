import Image from 'next/image';

interface BenfitItemProps {
  title: string;
  description: string;
  imageAlt: string;
  imageSrc: string;
}
const ProgramBenefitItem = ({ title, description, imageAlt, imageSrc }: BenfitItemProps) => {
  return (
    <div className="z-[1] mx-4 flex flex-col items-center gap-3 bg-gradient-to-b from-white/60 to-orange-200/60 p-6 text-center shadow-md lg:mx-0 lg:h-full lg:w-64">
      <Image alt={imageAlt} height={42} src={imageSrc} width={42} />
      <p className="font-semibold text-teal-900 lg:text-sm  lg:font-medium">{title}</p>
      <p className="text-sm font-normal lg:text-xs">{description}</p>
    </div>
  );
};

export default ProgramBenefitItem;
